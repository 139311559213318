<template>
    <div>
        <el-select :size="size" :multiple-limit="multipleType==1?1:0" style="width: 100%" value-key="qyWeixinUserId" v-model="selUser" multiple placeholder="请选择">
            <el-option
                v-for="item in userList"
                :key="item.qyWeixinUserId"
                :label="item.qyWeiXinUserName"
                :value="item">
            </el-option>
        </el-select>
    </div>
</template>
<script>
export default {
    data(){
        return {
            userList:[],
            selUser:this.selectUsers
        }
    },
    props:{
        size:{
            type:String,
            default:''
        },
        multipleType:Number,
        selectUsers:Array
    },
    watch:{
        'selUser':function(val){
            this.$emit('selectUsers',{value:val})
        },
        'selectUsers':function(val){
            this.selUser = val
        }
    },
    mounted() {
        this.initUserList()
    },
    methods:{
        //企微客服列表
        initUserList(){
            this.$apiGet('lechun-cms/scrmCalendar/getQwUser', {}).then(res => {
                this.userList = res.map(item=>{
                    return {qyWeiXinUserName:item.name,qyWeixinUserId:item.id}
                })
            })
        },
    }
}
</script>
<style scoped>

</style>