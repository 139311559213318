<template>
    <div>
        <el-card style="margin-bottom: 10px;font-size: 12px" v-for="item in chatResultList">
            <div style="display: flex;align-items: center;justify-content: space-between">
                <div style="display: flex;flex: 1">
                    <el-avatar style="margin-right: 10px;" shape="square" size="small" :src="item.speakType==2?item.avatar:'http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg'"></el-avatar>
                    <div style="flex: 1">
                        <p style="margin-bottom: 10px;font-weight: 600">{{item.speakType==2?item.name:'乐纯'}}<span v-if="item.speakType==1" style="color: #999999"> > {{item.name}}</span></p>
                        <p v-html="highLightSearchText(item.textContent)">{{item.textContent}}"</p>
                    </div>
                </div>
                <div>
                    <p>{{item.msgTime}}</p>
                    <el-link type="primary" style="margin-top:10px;width: 100px" @click="openUpDownRecord(item)">查找上下文</el-link>
                </div>
            </div>
        </el-card>
        <el-empty v-if="chatResultList.length<=0" description="暂无记录"></el-empty>
        <el-drawer
            :modal-append-to-body="false"
            title="搜索记录上下文"
            size="60%"
            :visible.sync="keyResultDrawer">
            <el-scrollbar class="drawer-content" v-loading="upDownLoading">
                <div v-for="item1 in resultUpDownRecordData" class="chat-item" :class="item1.speakType==2?'':'chat-item-reverse'">
                    <div class="chat-img"><el-avatar size="small" :src="item1.speakType==2?item1.avatar:'http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg'"></el-avatar></div>
                    <p class="chat-msg-time">{{item1.msgTime}}</p>
                    <div class="chat-miniprogram" v-if="item1.msgType=='weapp'">
                        <p style="display: flex;align-items: center;font-size: 10px"><el-avatar style="margin-right: 6px" size="small" src="http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg"></el-avatar> 乐纯</p>
                        <p>{{item1.weappTitle}}</p>
                        <img style="width: 200px;height: 150px" src="http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg">
                        <p style="border-top: 1px solid #dddddd;padding-top: 6px;font-size: 10px">小程序</p>
                    </div>
                    <div class="chat-content" v-else-if="item1.msgType=='text'" v-html="selCurId==item1.id?highLightSearchText(item1.textContent):item1.textContent"></div>
                    <div class="chat-content chat-image" v-else-if="item1.msgType=='image'||item1.msgType=='emotion'">
                        <el-image style="width: 100px;height: auto" :src="item1.ossUrl" :preview-src-list="[item1.ossUrl]"></el-image>
                    </div>
                    <div class="chat-content chat-voice" v-else-if="item1.msgType=='voice'" @click="playVoice(item1)">{{item1.amrTime}}"</div>
                    <div class="chat-content" v-else>[ {{item1.msgType}} ]</div>
                </div>
                <el-empty v-if="resultUpDownRecordData.length<=0" description="暂无记录"></el-empty>

            </el-scrollbar>
        </el-drawer>
    </div>
</template>
<script>
 export default {
     data(){
         return {
             keyResultDrawer:false,
             resultUpDownRecordData:[],
             upDownParam:{
                 currentPage:1,
                 pageSize:50,
                 qyWeixinUserid:'',
                 externalUserid:'',
                 sdate:'',
                 edate:'',
                 content:'',
                 curId:''
             },
             selCurId:'',
             upDownLoading:false
         }
     },
     props:{
         chatResultList:{
             type:Array,
             default:[]
         },
         searchParam:Object,

     },
     components:{

     },
     mounted() {

     },
     methods:{
         highLightSearchText(text){
             if(!text){
                 return false
             }
             let value = this.searchParam.content
             if(value){
                 let reg = new RegExp('</?span.*?>','gi')
                 text = text.replace(reg,'')
                 let reg1 = new RegExp(value,'gi')
                 let arr = text.match(reg1)
                 let i = -1
                 text = text.replace(reg1,()=>{
                     i++
                     return '<span style="color:#e50011;background: rgba(228,255,4,.4)">'+arr[i]+'</span>'
                 })
             }
             return text
         },
         //打开上下文
         openUpDownRecord(item){
             this.upDownLoading = true
             this.keyResultDrawer = true
             this.resultUpDownRecordData=[]
             this.selCurId = item.id
             let {date,...params} = this.searchParam

             let param = {
                 qyWeixinUserid: item.qy_weixin_userid,
                 curId: item.id,
                 externalUserid: item.externalUserid,
                 content:''
             }

             this.$apiGet('lechun-bi/generateScene/getQwChatList',{...this.upDownParam,...param}).then(res => {
                 //处理微信语音amr
                 this.resultUpDownRecordData = res.list
                 this.upDownLoading = false
                 this.$nextTick(()=>{
                     let dom = document.querySelector('.drawer-content .el-scrollbar__wrap')
                     dom.scrollTop = 10000000000
                 })
             })
         },
     }
 }
</script>
<style scoped lang="scss" rel="stylesheet/scss">
p,h3,h4,h5{
    margin: 0;
    padding: 0;
}
.chat-item{
    position: relative;
    padding-top: 20px;
    display: flex;
    margin-bottom: 20px;
    font-size: 12px;
}

.chat-item-reverse{
    flex-direction: row-reverse;
}
.chat-img{
    width: 28px;
    display: block;
    margin-right: 10px;
}
.chat-msg-time{
    position: absolute;
    top: 0;
    left: 38px;
    font-size: 10px;
    color: #999999;
}
.chat-item-reverse .chat-msg-time{
    left: auto;
    right: 38px;
}
.chat-img img{
    width: 28px!important;
}
.chat-item-reverse .chat-img{
    margin-left: 10px;
    margin-right: 0;
}

.chat-content{
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    white-space: pre-wrap;
    max-width: 80%;

}
.chat-image{
img{
    width: 100px;
    display: block;
}
}

.chat-item-reverse .chat-content{
    background: #a6e860;
}
.chat-item-reverse .chat-image{
    background: transparent;
}
.chat-miniprogram{
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 10px;
    width: 200px;
}
.chat-voice{
    border-radius: 5px;
    padding: 10px;
    width:100px;
    background: #f5f5f5 url("http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/icon-voice.png") center left no-repeat;
    background-size: 16px auto;
    padding-left: 26px;
    background-position-x: 5px;
}
.voice-text{
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    white-space: pre-wrap;
    max-width: 80%;
}
.drawer-content{
    padding: 15px 20px;
    box-sizing: border-box;
}
</style>